
import { Component, Vue } from 'vue-property-decorator'
import { HydrantDetail } from '@/types/hydrant.d'

@Component({
  name: 'HydrantList'
})
export default class extends Vue {
  private searchInfo = {
    projectName: '',
    fightingName: '',
    fightingNum: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData: HydrantDetail[] = []

  created () {
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{total: number;list: Array<HydrantDetail>}>(this.$apis.hydrant.selectFireFightingByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增服务设施
  addHydrant () {
    this.$router.push({ path: '/hydrantManage/add' })
  }

  editHydrant (id: string) {
    this.$router.push({ name: 'hydrantManageEdit', params: { id } })
  }

  deleteHydrant (fightingId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.hydrant.deleteFireFighting, { fightingId }).then(() => {
        this.$message.success('删除成功')
        this.searchData()
      })
    })
  }
}
